import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import Select from './fields/select';
import File from './fields/file';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"
const renderSelectField = ({
    input,
    placeholder,
    meta: { touched, error },
    children,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
            children={children}
        />
    )

const renderTextField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )
const renderFileField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <File
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )



class form extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.state = {}
    }

    submit(data) {
        console.log(data);


        fetch('https://dpsklas.novamedia.agency/competition', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(
                data
            )
        }).then((res) => res.json()).then((result) => {
            this.setState({
                _done: true
            })
        })


    }


    render() {
        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit(this.submit)}>
                <Container className="competition-form">

                    <Row>
                        <Col lg="6">
                            <Field
                                name="firstName"
                                component={renderTextField}
                                placeholder="Vaše ime"

                            ></Field>

                            <Field
                                name="lastName"
                                component={renderTextField}
                                placeholder="Vaše prezime"

                            ></Field>
                            <Field
                                name="phoneNumber"
                                component={renderTextField}
                                placeholder="Broj telefona"

                            ></Field>

                        </Col>
                        <Col lg="6">
                            <Field
                                name="email"
                                component={renderTextField}
                                placeholder="E-mail adresa"

                            ></Field>

                            <Field
                                name="position"
                                component={renderSelectField}
                                placeholder="Željena pozicija"

                            ><option value="Direktor">Direktor</option><option value="Direktor">Direktor</option></Field>
                            <Field
                                name="cv"
                                component={renderFileField}
                                placeholder="Vaš CV (pdf)"

                            ></Field>

                        </Col>
                        <Col lg="12">
                            <Field
                                name="message"
                                component={renderTextareaField}
                                placeholder="Dodatna poruka"

                            ></Field>

                        </Col>

                        <Col lg="3">
                            {this.state._done ?
                                <p>Vaša prijava je uspešno poslata, očekujte odgovor ubrzo</p>

                                :
                                <button className="form-button">KONKURIŠI</button>
                            }
                        </Col>

                    </Row>
                </Container>


            </form>
        )
    }
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
