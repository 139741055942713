import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.png';
import cert3 from '../assets/images/cert3.png';

import intobg from '../assets/images/bg1.png';
import banner1 from '../assets/images/bg2.png';


import location_icon from '../assets/images/location.svg';
import phone_icon from '../assets/images/phone.svg';
import mail_icon from '../assets/images/mail.svg';
import fb_icon from '../assets/images/facebook.svg';
import instagram_icon from '../assets/images/instagram.svg';
import youtube_icon from '../assets/images/youtube.svg';

import Map from '../components/map'
import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import moment from 'moment';
import ContactForm from '../components/forms/contactForm';

class NewsDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastNews: []
        };
    }

    componentDidMount() {
        fetch('https://dpsklas.novamedia.agency/news/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState(result)

        })

        fetch('https://dpsklas.novamedia.agency/lastNews/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({ lastNews: result })

        })

        window.scrollTo(0, 0);


    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            fetch('https://dpsklas.novamedia.agency/news/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState(result)

            })

            fetch('https://dpsklas.novamedia.agency/lastNews/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({ lastNews: result })

            })
            window.scrollTo(0, 0);


        }
    }


    render() {
        return (
            <div className="page-wrap">
                <div className="page-into">
                    <img src={intobg} />
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>NOVOSTI</h1>

                            </Col>
                            <Col lg="6">
                                <ul className="breadcrumb">
                                    <li><Link to="/">Početna</Link></li>
                                    <li><Link to="/">Novosti</Link></li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>


                </div>
                <Container >
                    <Row>
                        <Col lg="9" className="news-detail">
                            <h2>{this.state.name}</h2>
                            <p className="date">{moment.unix(this.state.timestamp).format('DD. MMMM YYYY.')}</p>
                            <p>{this.state.shortDescription}</p>
                            <img src={this.state.image} />
                            <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>


                        </Col>
                        <Col lg="3" className="side-news">
                            <h3>Najnovije novosti</h3>
                            {
                                this.state.lastNews.map((item, idx) => {
                                    return (
                                        <Link to={`/novosti/${item._id}`}>
                                            <article>
                                                <img src={item.image} />
                                                <h6>{item.name}</h6>
                                                <p className="date">{moment.unix(item.timestamp).format('DD. MMMM YYYY.')}</p>
                                                <p>{item.shortDescription && item.shortDescription.length > 81 ? item.shortDescription.substr(0, 81) + '...' : item.shortDescription}</p>
                                                <button>DETALJNIJE</button>
                                            </article>
                                        </Link>

                                    )
                                })
                            }

                        </Col>
                    </Row>
                </Container>


                <Container className="certificates">
                    <Row>
                        <Col lg="6">
                            <h6>FIRMA OD POVERENJA</h6>
                            <p>Sertifikati ISOQAR, UKAS i Firma od poverenja</p>
                        </Col>
                        <Col lg="6" className="items">
                            <img src={cert1} />
                            <img src={cert2} />
                            <img src={cert3} />

                        </Col>
                    </Row>

                </Container>


                <section className="contact-section">
                    <Container>

                        <Row>
                            <Col lg="6">
                                <h3>Kontakt</h3>
                                <h6>DPS KLAS GROUP D.O.O</h6>
                                <ul>
                                    <li><Isvg src={location_icon} /> Šabac, Savska 8, Srbija</li>
                                    <li><Isvg src={phone_icon} /> +381 21 654 654</li>
                                    <li><Isvg src={mail_icon} /> info@dpsklas.rs</li>
                                </ul>

                                <p>Pratite nas:</p>
                                <ul className="social">
                                    <li><a href="#" target="_blank"><Isvg src={fb_icon} /></a></li>
                                    <li><a href="#" target="_blank"><Isvg src={instagram_icon} /></a></li>
                                    <li><a href="#" target="_blank"><Isvg src={youtube_icon} /></a></li>

                                </ul>
                            </Col>
                            <Col lg="6" className="right-col">
                                <h3>Kontaktirajte nas</h3>
                                <p>Budite slobodni da nas kontaktirate.<br />Odgovorićemo Vam u što kraćem roku.</p>
                                <ContactForm />

                            </Col>
                        </Row>
                    </Container>
                </section>
                <Map {...this.props} />

            </div>
        );
    }
}

export default Page(NewsDetailPage);