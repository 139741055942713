import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';

export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div className="wrapper">
            <Header {...props} />
            <Wrapped {...props} />
            {
                props[0].location.pathname !== '/' ?
                <Footer {...props} />
                :
                null
            }
        </div>
    );
};

export default DefaultLayout;