import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import NewsPage from './views/newsPage';
import NewsDetailPage from './views/newsDetailPage';
import ContactPage from './views/contactPage';
import CompetitionPage from './views/competitionPage';
import EditPage from './views/editPage';
import Page from './views/page';


class Routes extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/stranica/:alias"
                            exact
                            render={(...renderProps) => (
                                <Page {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/editPage/:id/:token"
                            exact
                            render={(...renderProps) => (
                                <EditPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/novosti"
                            exact
                            render={(...renderProps) => (
                                <NewsPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/novosti/:id"
                            exact
                            render={(...renderProps) => (
                                <NewsDetailPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/kontakt"
                            exact
                            render={(...renderProps) => (
                                <ContactPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/zaposlenje"
                            exact
                            render={(...renderProps) => (
                                <CompetitionPage {...renderProps} {...this.props} />
                            )}
                        />




                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;