import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


/*header*/

import logo from '../assets/images/logo.svg';
import menu from '../assets/images/menu.svg';
import close from '../assets/images/close.svg';



import slide1 from '../assets/images/slide1.png';
import slide2 from '../assets/images/slide2.png';
import slide3 from '../assets/images/slide3.png';

import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.png';
import cert3 from '../assets/images/cert3.png';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0,
            width: 0,
            slides: [
                /*  {
                      subtitle: 'O NAMA',
                      title: 'Posao je poverenje',
                      content: 'Vrhunski i ujednačen kvalitet proizvoda, zadovoljstvo i trajno poverenje kupaca su osnovna načela na kojima se bazira poslovanje preduzeća DPS KLAS GROUP.',
                      buttonText: 'O NAMA',
                      buttonLink: '/',
                      image: slide1
                  },
                  {
                      subtitle: 'O NAMA',
                      title: 'Kvalitetne žitarice',
                      content: 'Vrhunski i ujednačen kvalitet proizvoda, zadovoljstvo i trajno poverenje kupaca su osnovna načela na kojima se bazira poslovanje preduzeća DPS KLAS GROUP.',
                      buttonText: 'O NAMA',
                      buttonLink: '/',
                      image: slide2
                  },
                  {
                      subtitle: 'O NAMA',
                      title: 'Koreni porodičnog posla',
                      content: 'Vrhunski i ujednačen kvalitet proizvoda, zadovoljstvo i trajno poverenje kupaca su osnovna načela na kojima se bazira poslovanje preduzeća DPS KLAS GROUP.',
                      buttonText: 'O NAMA',
                      buttonLink: '/',
                      image: slide3
                  }*/
            ]
        };
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slides.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slides.length ? 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    componentDidMount() {
        fetch('https://dpsklas.novamedia.agency/slides', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({ slides: result })

        })

        window.scrollTo(0, 0);



    }


    render() {
        const { activeIndex } = this.state;
        const slides = this.state.slides.map((item, idx) => {

            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}
                    slide={true}

                >
                    <img src={item.image} alt={item.title} />
                    <div className="content">

                        <Container>
                            <Row>
                                <Col lg="5" xs="9" className="content-col">
                                    <div className="overlay"></div>
                                    <div className="caption">
                                        <h6>{item.subtitle}</h6>
                                        <h3>{item.name}</h3>
                                        <p>{item.content}</p>
                                        <Link to={item.buttonLink ? item.buttonLink : ''} className="button">{item.buttonText}</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </CarouselItem>
            );
        });

        return (
            <div className="home-wrap">

                <div className="home-slider-wrap">
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                        touch={true}
                        className="home-slider"
                        interval={6000}
                    >
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />

                    </Carousel>
                    <div className="indicator">
                        <span>{activeIndex + 1 < 10 ? '0' + (activeIndex + 1) : activeIndex}</span>
                        <div></div>
                        <span>{this.state.slides.length < 10 ? '0' + (this.state.slides.length) : this.state.slides.length}</span>
                    </div>
                </div>

                <Container className="certificates">
                    <Row>
                        <Col lg="6">
                            <h6>FIRMA OD POVERENJA</h6>
                            <p>Sertifikati ISOQAR, UKAS i Firma od poverenja</p>
                        </Col>
                        <Col lg="6" className="items">
                            <img src={cert1} />
                            <img src={cert2} />
                            <img src={cert3} />

                        </Col>
                    </Row>

                </Container>

            </div>
        );
    }
}

export default Page(HomePage);